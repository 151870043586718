import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { PopoverStickOnHover } from '@tradetrax/web-common/lib/Popover';
import { plural } from '@tradetrax/web-common/lib/utils';
import navigate from 'app/navigate';

export const OverlayIncompleteStages = ({ stages }) => {
  let numberIncompleteStages = stages.filter(stage => !(stage.get('endTaskId') || stage.get('startTaskId'))).size;
  if (stages.size === numberIncompleteStages) numberIncompleteStages -= 1;
  const label = 'Incomplete Stages';
  const incompleteStagesText = (
    <>
      {plural(numberIncompleteStages, 'is', 'are')}
      <strong>{` ${numberIncompleteStages} ${plural(numberIncompleteStages, 'Stage')}`}</strong>
    </>
  );

  const popoverText = () => (
    <div className="d-flex pl-2 pt-2 pb-2">
      <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />
      <span>
        There {incompleteStagesText} that {plural(numberIncompleteStages, 'has', 'have')} not been set, you can set{' '}
        {plural(numberIncompleteStages, 'it', 'them')} later.
      </span>
    </div>
  );

  return (
    <PopoverStickOnHover popoverId="warning-popover" popover={popoverText()} delay={600} placement="bottom-end">
      <Label>{label}</Label>
    </PopoverStickOnHover>
  );
};

export const OverlayIncompleteStartDateConfirmation = ({ tasks }) => {
  const numberMissingTasks =
    tasks.get('isLoading') || tasks.get('isEmpty') ? 0 : tasks.filter(task => task.get('isRelatedTaskMissing')).size;
  const label = 'Missing Tasks';
  const incompleteTasksText = (
    <>
      {plural(numberMissingTasks, 'is', 'are')}
      <strong>{` ${numberMissingTasks} ${plural(numberMissingTasks, 'Task')}`}</strong>
    </>
  );
  const popoverText = () => (
    <div className="d-flex pl-2 pt-2 pb-2">
      <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />

      <span>
        There {incompleteTasksText} that {plural(numberMissingTasks, 'is', 'are')} missing.
        <div>Make sure to add {plural(numberMissingTasks, 'it', 'them')} to the Template.</div>
      </span>
    </div>
  );

  return (
    <PopoverStickOnHover popoverId="warning-popover" popover={popoverText()} delay={600} placement="bottom-end">
      <Label>{label}</Label>
    </PopoverStickOnHover>
  );
};

export const OverlayIncompleteSettings = ({ template }) => {
  const templateId = template.get('_id');
  const { isComplete, hasValidMultiFamilyDependencies, hasCompleteStages } = template.toObject();

  const popoverText = () => {
    return (
      <>
        <div
          className={cn('px-3 pt-3', hasValidMultiFamilyDependencies ? 'd-none' : 'd-flex', {
            'pb-3': hasCompleteStages,
          })}
        >
          <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />
          <span>Multi-Family Tasks must not have dependencies to Regular Tasks.</span>
        </div>
        <div className={cn('px-3 py-3', hasCompleteStages ? 'd-none' : 'd-flex')}>
          <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />
          <span>
            <Link to={navigate.from.TemplateDetails.to.TemplateSettings({ templateId }, false)}>Template Settings</Link>{' '}
            must be completed.
          </span>
        </div>
      </>
    );
  };

  if (isComplete) return null;

  return (
    <PopoverStickOnHover
      className="mr-3"
      popoverId="warning-popover"
      popover={popoverText()}
      delay={600}
      placement="bottom-end"
    >
      <Label>Incomplete Template</Label>
    </PopoverStickOnHover>
  );
};

export const OverlayLockedTemplate = ({ isLocked, onUse }) => {
  if (!isLocked && !onUse) return null;
  const message = isLocked
    ? 'This Template cannot be edited since it contains Multi-Family Tasks and a Job was already created.'
    : 'Multi-Family functionality is disabled because a Job was already created.';

  const popoverText = () => {
    return (
      <div className="d-flex p-3">
        <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />
        <span>{message}</span>
      </div>
    );
  };

  return (
    <PopoverStickOnHover
      className="mr-3"
      popoverId="warning-popover"
      popover={popoverText()}
      delay={600}
      placement="bottom-end"
    >
      <Label>
        <FontAwesomeIcon icon="lock" className="font-size-14 mr-2 mt-1" />
        Template {!isLocked ? 'Partially ' : ''}Locked
      </Label>
    </PopoverStickOnHover>
  );
};

const Label = styled.span`
  background-color: ${colors.yellow};
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
`;

import React from 'react';
import { PopoverMultiFamily } from '../Popover/MultiFamily.jobs.popover';
import { Link } from 'react-router-dom';

export const JobName = ({ task, navigate }) => {
  const job = task.get('job');
  const jobId = job?.get('id');
  const lotNumber = task?.getIn(['job', 'lotNumber']);
  const truncatedLotNumber = lotNumber?.length > 10 ? lotNumber.slice(0, 10) + '...' : lotNumber;
  const name = job.get('name');
  const totalMultifamilyJobs = task?.getIn(['multiFamily', 'jobs'])?.size;

  if (task.get('isMultiFamily') && totalMultifamilyJobs > 1) {
    return (
      <PopoverMultiFamily multiFamily={task.get('multiFamily')}>
        <span className="clickable-text text-gray">{`${totalMultifamilyJobs} Jobs`}</span>
      </PopoverMultiFamily>
    );
  }
  return (
    <>
      <Link to={navigate.to.JobDetails({ jobId }, false)} className="secondary-link">
        <div className="text-truncate">{name}</div>
      </Link>
      {lotNumber && <span className="d-block text-muted text-truncate">{`LOT #${truncatedLotNumber}`}</span>}
    </>
  );
};

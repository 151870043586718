export const EventHandlers = ({ controller }) => {
  const onMultiFamilyChange = ({ rowData }) => e => {
    e.stopPropagation();

    const { checked } = e.target;

    controller.updateTaskField(rowData, 'isMultiFamily', checked);
  };

  const onPreConstructionChange = rowData => e => {
    e.stopPropagation();
    const { checked } = e.target;
    controller.updateTaskField(rowData, 'isPreConstruction', checked);
  };

  return {
    onMultiFamilyChange,
    onPreConstructionChange,
  };
};

import React, { useState } from 'react';
import { Modal, Form, Button, FormControl, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton, Typeahead } from '@tradetrax/web-common';
import cn from 'classnames';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { Map } from 'immutable';
import TaskDependenciesController from './TaskDependenciesController';
import { colors } from '@tradetrax/web-common/lib/theme';
import { TrxTooltip as Tooltip } from '@tradetrax/web-common/lib/Tooltip';

const ERROR_MAP = {
  required: 'Task required',
  validate: 'Circular Dependency',
};

export default function TaskDependencies({ controller, task, tasks: tasksAndStages, job, close }) {
  const isMultiFamily = task.get('isMultiFamilyFromTemplate');
  const tasks = tasksAndStages.filter(task => {
    if (isMultiFamily) return !task.get('isStageTask') && task.get('isMultiFamilyFromTemplate');
    return !task.get('isStageTask');
  });
  const dependenciesController = TaskDependenciesController({ controller, task, tasks: tasksAndStages, job });
  const { fields, clearErrors } = dependenciesController;
  const onClose = () => {
    clearErrors();
    close();
  };
  const taskOrStage = task.get('isStage') ? 'Stage' : 'Task';

  return (
    <Modal show={true} onHide={onClose} size="lg" id="task-dep">
      <Modal.Header closeButton>
        <Modal.Title>
          Edit {isMultiFamily ? 'Multi-Family ' : ''}Dependencies{<br />}
          <h6 className="font-weight-bold">{`${taskOrStage}: ${task.get('name')}`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={dependenciesController.onSubmit}>
        <Modal.Body>
          {fields.map((predecessor, index) => {
            predecessor.index = index;
            return (
              <Form.Row key={predecessor.id}>
                <DependencyRow
                  predecessor={predecessor}
                  controller={dependenciesController}
                  tasks={tasks}
                  currentTask={task}
                  isFirstRow={index === 0}
                  clearError={clearErrors}
                  isMultiFamily={isMultiFamily}
                />
              </Form.Row>
            );
          })}
          <Form.Row>
            <Col sm="11" className="text-center pt-2">
              {fields.length === 0 && <p className="text-secondary mb-4">No predecessors added for this task.</p>}
            </Col>
            <Form.Group as={Col} sm={{ span: 1 }}>
              <Button size="lg" onClick={dependenciesController.addRow}>
                <FontAwesomeIcon icon="plus" />
              </Button>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton variant="secondary" onClick={() => close()}>
            Cancel
          </PrimaryButton>
          <PrimaryButton type="submit" disabled={dependenciesController.errors.predecessors}>
            Update
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const DependencyRow = ({
  predecessor,
  controller,
  tasks,
  currentTask,
  isFirstRow = false,
  clearError,
  isMultiFamily,
}) => {
  const { register, errors, validateCircularDependency, validateLagTime, remove, control } = controller;
  let taskValue = predecessor.taskId;
  taskValue = typeof taskValue === 'number' ? taskValue : '';
  const typeValue = predecessor.dependencyType || 'FS';
  const currentTaskId = currentTask.get('id');

  const TASKS = React.useMemo(() => tasks.filter(task => currentTaskId !== task.get('id')).toArray(), [
    tasks,
    currentTaskId,
  ]);

  const byKey = React.useMemo(() => tasks.reduce((memo, value) => memo.set(value.get('id'), value), Map()), [tasks]);

  const getRowIndex = id => {
    const predecessorTask = tasks.find(task => id === task.get('id'));
    return predecessorTask ? predecessorTask.get('rowIndex') : '';
  };
  const [rowIndex, setRowIndex] = useState(getRowIndex(predecessor.taskId));
  const removeRow = index => {
    clearError(`predecessors[${predecessor.index}].taskId`);
    remove(index);
  };
  const onChangeLagTime = event => {
    const input = event.target;
    const isValid = /^-?\d{0,3}$/.test(input.value);

    if (!isValid) {
      input.value = input.value.slice(0, -1);
    }

    validateLagTime(input.value, predecessor.index);
  };

  const taskError = errors.predecessors ? errors.predecessors[`${predecessor.index}`]?.taskId : null;
  const typeErrors = errors.predecessors ? errors.predecessors[`${predecessor.index}`]?.dependencyType : null;
  const lagTimeError = errors.predecessors ? errors.predecessors[`${predecessor.index}`]?.lagTime : null;
  const errorMessage = taskError ? ERROR_MAP[taskError.type] : '';

  return (
    <>
      <Form.Group as={Col} sm={1} className="text-truncate">
        {isFirstRow && <Form.Label>Row #</Form.Label>}
        <Tooltip text={rowIndex}>
          <IndexField size="lg" value={rowIndex} readOnly disabled />
        </Tooltip>
      </Form.Group>
      <Form.Group as={Col} sm={5}>
        {isFirstRow && <Form.Label>{isMultiFamily ? 'Multi-Family ' : ''}Task</Form.Label>}
        <Controller
          control={control}
          defaultValue={taskValue}
          rules={{ required: true, validate: validateCircularDependency }}
          name={`predecessors[${predecessor.index}].taskId`}
          render={({ onChange, onBlur, value }) => {
            const selected = value ? [byKey.get(value)] : [];

            return (
              <Typeahead
                id={`predecessors[${predecessor.index}].taskId`}
                className={cn('typeahead-dropdown-view', {
                  'is-invalid': taskError,
                  'is-valid': !taskError,
                })}
                size="lg"
                filterSelected={x => x}
                placeholder={`Choose a ${isMultiFamily ? 'Multi-Family ' : ''}Task`}
                labelKey={option => option?.get('name') || ''}
                onChange={([e]) => {
                  const id = e ? e.get('id') : '';
                  onChange(id);
                  setRowIndex(getRowIndex(id));
                }}
                onBlur={onBlur}
                selected={selected}
                options={TASKS}
                isInvalid={!!taskError}
              />
            );
          }}
        />
        {/* <FormControl
          as="select"
          size="lg"
          isInvalid={taskError}
          name={`predecessors[${predecessor.index}].taskId`}
          ref={register({ required: true, validate: validateCircularDependency })}
          defaultValue={taskValue}
          onChange={e => taskChangeHandler(e)}
        >
          <option value="" disabled>
            Choose from library
          </option>
          {tasks.map(task =>
            currentTaskId !== task.get('id') ? (
              <option key={task.get('id')} value={task.get('id')}>
                {task.get('name')}
              </option>
            ) : null
          )}
        </FormControl> */}
        <Form.Control.Feedback type="invalid">
          <FontAwesomeIcon icon="circle-exclamation" className="mr-1" />
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} sm={4}>
        {isFirstRow && <Form.Label>Type</Form.Label>}
        <FormControl
          as="select"
          size="lg"
          isInvalid={typeErrors}
          name={`predecessors[${predecessor.index}].dependencyType`}
          ref={register({ required: true })}
          defaultValue={typeValue}
        >
          <option value="" disabled>
            Select type
          </option>
          <option value="FS">Finish to Start</option>
          <option value="SS">Start to Start</option>
          <option value="FF">Finish to Finish</option>
          <option value="SF">Start to Finish</option>
        </FormControl>
        <Form.Control.Feedback type="invalid">Required Field</Form.Control.Feedback>
      </Form.Group>
      <NoPaddingRightFormGroup as={Col} sm={1}>
        {isFirstRow && <Form.Label>Lag</Form.Label>}
        <LagTimeInput
          size="lg"
          name={`predecessors[${predecessor.index}].lagTime`}
          isInvalid={lagTimeError}
          ref={register()}
          defaultValue={predecessor.lagTime}
          onChange={onChangeLagTime}
        />
        <Form.Control.Feedback type="invalid" className={cn({ 'd-block': !!lagTimeError })}>
          <FontAwesomeIcon icon="circle-exclamation" />
          {` `}
          {lagTimeError && lagTimeError.type === 'min' && <FontAwesomeIcon icon={faArrowUp} />}
          {lagTimeError && lagTimeError.type === 'max' && <FontAwesomeIcon icon={faArrowDown} />}
          {lagTimeError && lagTimeError.message}
        </Form.Control.Feedback>
      </NoPaddingRightFormGroup>
      <Form.Group as={Col} sm={1}>
        {isFirstRow && <Form.Label>&nbsp;</Form.Label>}
        <Button variant="secondary" size="lg" onClick={() => removeRow(predecessor.index)}>
          <FontAwesomeIcon icon="trash" />
        </Button>
      </Form.Group>
    </>
  );
};

const NoPaddingRightFormGroup = styled(Form.Group)`
  .form-control-lg {
    padding: 0;
    text-align: center;
  }
  &.was-validated .form-control:invalid,
  .form-control.is-invalid {
    padding-right: 0 !important;
  }
  .d-block.invalid-feedback {
    white-space: nowrap;
  }
`;

const LagTimeInput = styled(FormControl)`
  background: none !important;
`;

export const IndexField = styled(Form.Control)`
  width: 50px;
  padding-left: 13px;
  padding-right: 13px;
  background-color: ${colors.white} !important;
  color: ${colors.gray800} !important;
  cursor: default;
`;

import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { PrimaryButton, Typeahead } from '@tradetrax/web-common';
import { trimSpaces } from '@tradetrax/web-common/lib/utils';
import { useTemplates } from '../../Job/useTemplates';
import * as OutSelections from '@tradetrax/web-common/lib/Typeahead/OutSelectionsTypeahead';
import { buildersService } from 'services';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Building Name is required.')
    .matches(/^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~\s]+$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
  template: yup
    .object()
    .shape({
      _id: yup.string().required('Template is required'),
      name: yup.string().required('Template is required'),
    })
    .nullable()
    .required('Template is required'),
  jobs: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          _id: yup.string().required(),
          name: yup.string().required(),
        })
        .required('You must select at least one Job')
    )
    .min(2, 'You must select at least 2 Jobs'),
});

function createBuilding({ form, controller, community, setError, alert, close }) {
  const { template, jobs, name } = form;
  const communityId = community._id;
  const templateId = template._id;
  const jobIds = jobs.map(job => job._id);

  return controller
    .createBuilding({ communityId, templateId, jobIds, name })
    .then((/* buildingId */) => {
      alert.success({
        message: 'Building successfully created.',
      });
      close();
    })
    .catch(response => {
      if (response.type === 'building-name-already-exists-for-community')
        setError('name', { type: 'notMatch', message: 'Building already exist in this Community' });
      else {
        //TODO: display the error on the popup to allow the user modify the fields and try again.
        alert.error({ message: 'There was a problem creating this Building. Please try again.' });
        close();
      }
    });
}

// // TODO: validate duplicated building name
// function validateIfBuildingNameExist(jobs, watch, setError, clearErrors) {
//   const { name, community } = watch();

//   const communityIdSelected = community ? community._id : '';
//   const jobNameExist = jobs.filter(
//     job => job.get('name').toLowerCase() === name.toLowerCase() && job.get('communityId') === communityIdSelected
//   );

//   if (jobNameExist.size > 0) setError('name', { type: 'notMatch', message: 'Job already exist in this Community' });
//   else clearErrors('name');
// }

export function BuildingNew({ controller, community, alert, close }) {
  const isMultiFamily = true;
  const templates = useTemplates(isMultiFamily);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    watch,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      template: null,
      jobs: [],
    },
  });
  const { template } = watch();
  const communityJobs = useCommunityJobs(community, template);
  const onSubmit = form => createBuilding({ form, controller, community, setError, alert, close });
  const resetJobs = template => {
    setValue('jobs', []);
    return template;
  };

  return (
    <Modal show={true} onHide={close} data-testid="modal-job-new">
      <Modal.Header closeButton>
        <div className="modal-title">
          <h4 className="font-weight-bold">Create New Building</h4>
          <h6 className="font-weight-bold">Community: {community?.name}</h6>
        </div>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formName">
            <Form.Label>Building Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              isInvalid={!!errors.name}
              isValid={dirtyFields.name && !errors.name}
              ref={register}
              autoComplete="off"
              placeholder="Ex. Building A"
              // onBlur={() => validateIfBuildingNameExist(jobs, watch, setError, clearErrors)}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.name?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" />
              {` Building name is valid`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formTemplate">
            <Form.Label>Template</Form.Label>
            <Controller
              control={control}
              name="template"
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-job-template"
                  name="template"
                  placeholder="Choose from Template Library"
                  labelKey={option => option.name}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  selected={value ? [value] : []}
                  options={templates}
                  onBlur={onBlur}
                  onChange={([template]) => onChange(resetJobs(template))}
                  isInvalid={!!errors.template}
                  icon="magnifying-glass"
                  className={cn({
                    'is-invalid': errors.template,
                    'is-valid': !errors.template,
                  })}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              Template is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formJobs">
            <Form.Label>Job(s) from Template</Form.Label>
            <Controller
              control={control}
              name="jobs"
              render={({ onChange, onBlur, value }) => (
                <OutSelections.Typeahead
                  id="building-jobs-typeahead"
                  size="lg"
                  labelKey={option => option.name}
                  placeholder={'Choose Job'}
                  emptyLabel="No Jobs from this Template."
                  disabled={!template}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  options={communityJobs}
                  selected={value}
                  isInvalid={!!errors.jobs}
                  updateSelected={onChange}
                  itemClassName={() => ''}
                  clearButton
                >
                  <Form.Control.Feedback type="invalid">
                    <FontAwesomeIcon icon="circle-exclamation" />
                    {errors.jobs?.message}
                  </Form.Control.Feedback>
                  <OutSelections.Selected selected={value} onChange={onChange} labelKey={option => option.name} />
                </OutSelections.Typeahead>
              )}
            />
          </Form.Group>
          <Modal.Footer className="px-0">
            <PrimaryButton type="submit">Create Building</PrimaryButton>
          </Modal.Footer>
        </Modal.Body>
      </Form>
    </Modal>
  );
}

const useCommunityJobs = (community, template) => {
  const [jobs, setJobs] = React.useState([]);
  const communityId = community?._id ?? null;
  const templateId = template?._id ?? null;

  React.useEffect(() => {
    if (templateId === null) {
      setJobs([]);
    } else {
      buildersService
        .readCommunityJobsQuery({}, { params: { communityId }, query: { templateId } })
        .then(jobs => jobs.map(job => job))
        .then(setJobs);
    }
  }, [communityId, templateId, setJobs]);

  return jobs;
};

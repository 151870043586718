import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm, Controller as FormController } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typeahead, PrimaryButton } from '@tradetrax/web-common';
import { trimSpaces, emptyStringToNull } from '@tradetrax/web-common/lib/utils';

function validateIfCommunityNameExist(value, setError, communities) {
  const communityNameExist = communities.filter(community => community.get('name') === value);
  if (communityNameExist.size > 0) {
    setError('name', { type: 'notMatch', message: 'Community name already exists' });
    return false;
  }
  return true;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9.!#$%&'*+\-/=?^_`{|}~\s]+$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
  address: yup
    .string()
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters')
    .notRequired()
    .transform(emptyStringToNull)
    .nullable(),
});

export default function CommunityForm({ title, communities, users, onSubmit, cancel, accept }) {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      address: '',
      areaManagersIds: '',
    },
  });

  const formSubmit = form => {
    if (validateIfCommunityNameExist(form.name, setError, communities)) {
      onSubmit(form);
      accept();
    }
  };

  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(formSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              isInvalid={!!errors.name}
              isValid={dirtyFields.name && !errors.name}
              ref={register}
              placeholder="Ex. Highland Park"
              onBlur={e => validateIfCommunityNameExist(e.target.value, setError, communities)}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.name?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" />
              {` Community name is valid`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              isInvalid={!!errors.address}
              isValid={dirtyFields.address && !errors.address}
              ref={register}
              placeholder="Ex. 4122 W Monte Vista"
            />
            <span className="text-gray-400 font-size-14">* Optional</span>
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.address?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Area Manager</Form.Label>
            <FormController
              name="areaManagersIds"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="areaManagersIds"
                  placeholder="Choose Area Manager"
                  options={users.toJS()}
                  labelKey={option => `${option.firstName} ${option.lastName}`}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                />
              )}
            />
            <span className="text-gray-400 font-size-14">* Optional</span>
          </Form.Group>
          <Modal.Footer className="pr-0 pt-3 mt-4">
            <PrimaryButton type="submit">Create Community</PrimaryButton>
          </Modal.Footer>
        </Modal.Body>
      </Form>
    </Modal>
  );
}

import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { fromJS } from 'immutable';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import { handleTableScrollStickyColumn2 } from '@tradetrax/web-common/lib/utils';
import { EmptyState } from '@tradetrax/web-common/lib/EmptyState';
import { AssigneeSelect } from '@tradetrax/web-common/lib/AssigneeSelect';
import { SelectAllCheckbox } from '@tradetrax/web-common/lib/OuttakeIntake/SelectAllCheckbox';
import { MINIMUM_BATCH_SIZE } from './OuttakeController';
import { ALL_TASKS } from '@tradetrax/web-common/lib/OuttakeIntake/TasksBySelect';
import { useAppContext } from 'app/App.context';
import { MultiFamilyIcon } from '@tradetrax/web-common/lib/Popover/MultiFamily.icons.popover';
import { JobName } from '@tradetrax/web-common/lib/OuttakeIntake/JobName';
import navigate from 'app/navigate';

const COL_WIDTHS = {
  span: 32,
  jobName: 18 * 16,
  assignee: 16 * 16,
  taskName: 16 * 15,
};

const getColumnLabel = tab => {
  if (tab === 'account') return 'Account Assignee';
  if (tab === 'super') return 'Super';
  if (tab === 'scheduler') return 'Scheduler';
};

const getTaskAssignee = (task, tab) => {
  if (tab === 'account') return [task.get('assigneeAccountName'), task.get('assigneeAccountId')];
  if (tab === 'super') return [task.getIn(['userSuper', 'name']), task.getIn(['userSuper', '_id'])];
  if (tab === 'scheduler') return [task.getIn(['userScheduler', 'name']), task.getIn(['userScheduler', '_id'])];
};
const getCurrentAssignee = (task, tab) => {
  const [name, _id] = getTaskAssignee(task, tab);
  return _id ? fromJS({ _id, name }) : null;
};

export function OuttakeTasks({ tab, state, controller, communityId, loaderRef, isFiltering }) {
  const { appState, hasPermission } = useAppContext();
  const { account } = appState.toObject();
  const { tasks, totalCount, maxCount } = state.get('allTasks').toObject();
  const loggedUserId = appState.getIn(['user', '_id']);
  const isEmpty = totalCount === 0;
  const label = getColumnLabel(tab);
  const canAssignUsers = tab !== 'account' ? hasPermission('task_user_assignment') : true;
  const scrollDivRef = useRef(null);
  const selectedTasks = state.getIn(['selectedTasks', tab]);

  const rowClassName = index => {
    const task = tasks.get(index);
    const className = !task || task === EMPTY_ROW ? 'loading' : '';
    if (index < 0 || !task) return className;
    const isSelected =
      selectedTasks.get('taskIds').indexOf(task.get('id')) >= 0 ||
      (selectedTasks.get('selectAll') && selectedTasks.get('notIncludeTaskIds').indexOf(task.get('id')) < 0);
    return `${className} ${isSelected ? 'selected' : ''}`;
  };
  const loadMoreRows = params => controller.readCommunityTasks({ ...params, communityId });
  const title = isFiltering ? 'No Matches Found' : 'No Tasks Added';
  const body = isFiltering
    ? "When there's a result that meets the filters, it will appear here."
    : 'When a Task of a Job from this Community is assigned to your account it will appear here.';

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);
  useEffect(() => {
    handleTableScrollStickyColumn2({ target: scrollDivRef.current }, scrollDivRef);
  }, [tasks]);
  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={loadMoreRows}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
        ref={loaderRef}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div
            style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden' }}
            className={cn({ 'd-none': isEmpty })}
            ref={scrollDivRef}
          >
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1100 ? width : 1100;
                return (
                  <Table
                    className="trx-table stickyTable stickyColumn2"
                    estimatedRowSize={80}
                    headerHeight={40}
                    height={height}
                    onRowsRendered={onRowsRendered}
                    overscanRowCount={2}
                    ref={registerChild}
                    rowCount={Math.min(maxCount, totalCount)}
                    rowClassName={({ index }) => rowClassName(index)}
                    rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                    rowHeight={80}
                    width={minWidth}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column dataKey="" label="" width={COL_WIDTHS.span} />
                    <Column
                      label="Task Name"
                      dataKey="task"
                      minWidth={350}
                      width={COL_WIDTHS.taskName}
                      headerClassName="d-flex align-items-center"
                      headerRenderer={() => (
                        <SelectAllCheckbox
                          selectedTasks={selectedTasks}
                          controller={controller}
                          show={canAssignUsers}
                        />
                      )}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const isChecked = selectedTasks.get('taskIds').indexOf(rowData.get('id')) >= 0;
                        const isExcluded = selectedTasks.get('notIncludeTaskIds').indexOf(rowData.get('id')) >= 0;
                        return (
                          <div className="d-flex align-items-center">
                            <input
                              className={cn('mr-2', { 'd-none': !canAssignUsers })}
                              type="checkbox"
                              checked={(selectedTasks.get('selectAll') || isChecked) && !isExcluded}
                              onChange={({ target }) =>
                                controller.onSelectCheckbox({
                                  checked: target.checked,
                                  task: rowData,
                                  tab,
                                  tasksBy: ALL_TASKS,
                                })
                              }
                            />
                            <span className="text-truncate font-weight-bold">{cellData.get('name')}</span>
                            <MultiFamilyIcon task={cellData} isMultiFamily={!!cellData.get('multiFamily')} />
                          </div>
                        );
                      })}
                    />
                    <Column
                      label="Job"
                      dataKey="job"
                      width={COL_WIDTHS.jobName}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const linkToJob = navigate.to.JobDetails({ jobId: cellData.get('id') }, false);
                        return <JobName job={cellData} task={rowData.get('task')} linkToJob={linkToJob} />;
                      })}
                    />
                    <Column
                      label={label}
                      dataKey="task"
                      width={COL_WIDTHS.assignee}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        if (tab === 'account')
                          return (
                            <AssigneeSelect
                              index={rowIndex}
                              users={account.get('users')}
                              options={fromJS(appState.get('companies'))}
                              currentUser={getCurrentAssignee(cellData, tab)}
                              loggedUserId={account.get('_id')}
                              hasPermission={canAssignUsers}
                              labelKey={option => option.name || option.firstName}
                              onChange={company => {
                                controller.assignTask({ company: fromJS(company), task: rowData });
                              }}
                            />
                          );
                        else
                          return (
                            <AssigneeSelect
                              index={rowIndex}
                              users={account.get('users')}
                              options={account.get('usersActive')}
                              currentUser={getCurrentAssignee(cellData, tab)}
                              loggedUserId={loggedUserId}
                              hasPermission={canAssignUsers}
                              onChange={assignee => {
                                controller.assignSuperSchedulerToTask({
                                  assignee: fromJS(assignee),
                                  tab,
                                  task: rowData,
                                });
                              }}
                            />
                          );
                      })}
                    />
                    <Column dataKey="" label="" width={COL_WIDTHS.span} />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
      {isEmpty && <EmptyState icon="wrench" title={title} body={body} />}
    </>
  );
}

import React from 'react';
import { Form } from 'react-bootstrap';
import { isFunction } from 'lodash';

export const NumberInput = ({
  children,
  defaultValue = 1,
  disabled = false,
  onChange,
  min = 1,
  max = 99,
  size = 'sm',
  className = '',
  width = 55,
}) => {
  const timerRef = React.useRef();
  const [currentValue, setCurrentValue] = React.useState(defaultValue);

  // debounce event to prevent several calls to BE
  const onInputChange = React.useCallback(
    e => {
      let { value } = e.target;
      if (value.length > 2) value = value.substring(0, 2);

      const newValue = parseInt(value, 10);
      if (isNaN(newValue)) return;

      setCurrentValue(newValue);

      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        onChange(parseInt(value, 10) || 1);
      }, 1000);
    },
    [onChange]
  );

  const onKeyDown = e => {
    const { key, currentTarget } = e;
    if (key === 'e' || key === 'E') e.preventDefault();
    if (/^\d$/.test(key) && currentTarget.value.length >= 2) e.preventDefault();
  };

  const Content = () => {
    if (isFunction(children)) return children(currentValue);
    return null;
  };

  return (
    <>
      <Form.Control
        className={className}
        type="number"
        size={size}
        min={min}
        max={max}
        onKeyDown={onKeyDown}
        style={{ width }}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onInputChange}
        onBlur={e => {
          if (e.target.value === '') {
            e.target.value = defaultValue;
          }
        }}
      />
      <Content />
    </>
  );
};

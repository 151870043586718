import React from 'react';
import cn from 'classnames';
import { plural } from '../../utils';
import { RootCauseTypeAhead } from './CycleTime.task.typeahead';
import { useTasks } from './RootCause.useTasks';

const TO_TASK = 'Attribute Cycle Time to another Task';
const TO_BUILDER = 'Attribute Cycle Time to Builder';

export const CycleTimeImpact = ({ updateRequestState, disabled = false }) => {
  const { jobDelay, task, checked, setValue } = updateRequestState;
  const jobTasks = useTasks(task);
  const toggle = option => {
    setValue('checked', option === checked ? null : option);
  };
  const isJobDelayed = (jobDelay || 0) > 0;
  const jobImpactText =
    jobDelay === 0 ? 'Cycle Time Impact' : isJobDelayed ? 'Increase Cycle Time' : 'Reduce Cycle Time';

  return (
    <>
      <li
        className={cn('border-bottom-0', {
          'text-danger': isJobDelayed,
          'text-success': !isJobDelayed && jobDelay !== 0,
        })}
      >
        <label>{jobImpactText}</label>
        <span>{plural.day(Math.abs(jobDelay))}</span>
      </li>
      <AttributeCycleTimeTo
        option="task"
        onCheck={toggle}
        isChecked={checked === 'task'}
        title={TO_TASK}
        disabled={disabled}
      >
        <RootCauseTypeAhead kind="task" jobTasks={jobTasks} selectTask={setValue('rcTaskId')} />
      </AttributeCycleTimeTo>
      <AttributeCycleTimeTo
        option="builder"
        onCheck={toggle}
        isChecked={checked === 'builder'}
        title={TO_BUILDER}
        disabled={disabled}
      >
        <RootCauseTypeAhead
          kind="builder"
          jobTasks={jobTasks}
          selectTask={setValue('rcTaskIdBuilder')}
          className="mb-3"
        />
      </AttributeCycleTimeTo>
    </>
  );
};

const AttributeCycleTimeTo = ({ hidden, option, onCheck, isChecked, title, disabled, children }) => {
  const onChange = e => {
    e.stopPropagation();
    setTimeout(() => {
      onCheck(option);
    }, 1);
  };

  return (
    <>
      <li className="border-bottom-0">
        <small className={cn('ml-5', { 'text-gray-200': disabled })}>{title}</small>
        <input type="checkbox" disabled={disabled} checked={isChecked} onChange={onChange} />
      </li>
      <li className={cn('small-li', { 'd-flex': isChecked, 'd-none': !isChecked })}>
        {children}
        <span className="ml-4">&nbsp;</span>
      </li>
    </>
  );
};

export const StageCycleTimeImpact = ({ stageImpact = 0 }) => {
  const isStageDelayed = stageImpact > 0;
  const stageImpactText =
    stageImpact === 0
      ? 'Stage Cycle Time Impact'
      : isStageDelayed
      ? 'Increase Stage Cycle Time'
      : 'Reduce Stage Cycle Time';

  return (
    <>
      <li
        className={cn('border-bottom', {
          'text-danger': isStageDelayed,
          'text-success': !isStageDelayed && stageImpact !== 0,
        })}
      >
        <label className="mb-0">{stageImpactText}</label>
        <span>{plural.day(Math.abs(stageImpact))}</span>
      </li>
    </>
  );
};

import React from 'react';
import cn from 'classnames';
import { List } from 'immutable';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../../Button';
import * as OutSelections from '../../Typeahead/OutSelectionsTypeahead';

export function EditView({ state, controller, accept }) {
  const { selected, isSaveDisabled, options } = state.toObject();
  const showMessage = !isSaveDisabled && selected.some(item => item.status === 'inactive');

  const onClick = () => {
    accept({ areaManagers: List(selected) });
  };

  return (
    <>
      <Modal.Body>
        <OutSelections.Typeahead
          id="area-manager-typeahead"
          size="lg"
          labelKey={option => option.get('name')}
          placeholder="Choose Area Manager"
          itemClassName={option => option.get('status')}
          options={options}
          selected={selected}
          updateSelected={selected => controller.addAreaManager(selected)}
          clearButton
        >
          <OutSelections.Selected
            selected={selected}
            onChange={selected => controller.addAreaManager(selected)}
            labelKey={option => option.get('name')}
          />
        </OutSelections.Typeahead>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-row">
          <span className={cn({ 'd-none': !showMessage })}>
            <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-2" />
            Deactivated Users will be automatically removed from this list.
          </span>
          <div className="d-flex justify-content-end">
            <PrimaryButton onClick={onClick} disabled={isSaveDisabled}>
              Save
            </PrimaryButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}

import React from 'react';
// import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskDurationInput } from '../../../Job/TaskDurationInput';
import { getPredecessorsString, hasCrossMFDependency } from '../TemplateDetailsContext';
import { TableRowDropDown } from '@tradetrax/web-common';
import { SubTasksList, ItemContainer, Divider, TaskNameContainer } from '@tradetrax/web-common/lib/Stages';
import { DraggableArea } from '@tradetrax/web-common/lib/Stages/DragNDrop/DraggableArea';
import { EventHandlers } from '../EventHandlers.shared';

export const InnerTasksList = ({
  rowData,
  rowIndex,
  controller,
  dndController,
  expandedRows,
  getCellHeight,
  width,
  isLocked,
}) => {
  const tasks = rowData.get('tasks');
  const containSubTasks = task => task.get('children').size > 0;
  const onClickExpand = task => {
    controller.toggleRow('stages', task, rowIndex);
  };

  return (
    <>
      {tasks.map((task, index) => {
        const canExpand = containSubTasks(task);
        const isExpanded = !!expandedRows.get(String(task.get('id')));
        const icon = isExpanded ? 'chevron-up' : 'chevron-down';
        const height = getCellHeight(task);
        return (
          <ItemContainer
            key={`task-${index}`}
            className="pl-4"
            height={height}
            data-task-order={task.get('order')}
            data-stage-id={task.get('stageId')}
          >
            <Divider />
            <div className="d-flex flex-row align-items-center">
              <DraggableArea
                controller={dndController}
                task={task}
                disabled={isLocked}
                className={cn('drag-icon font-size-14 mr-4', { 'pr-4': !canExpand, disabled: isLocked })}
              />
              {canExpand && <FontAwesomeIcon icon={icon} className="mr-2" onClick={() => onClickExpand(task)} />}
              <TaskNameContainer
                text={task.get('name')}
                width={width - 100}
                isKeyFinish={task.get('isKeyFinish')}
                isKeyStart={task.get('isKeyStart')}
                isPreConstruction={task.get('isPreConstruction')}
                isMultiFamily={task.get('isMultiFamily')}
                isInner
              />
            </div>
            {isExpanded && (
              <SubTasksList
                className="ml-5 pl-3"
                subTasks={task.get('children')}
                rowData={task}
                controller={controller}
                isTemplate
                isLocked={isLocked}
              />
            )}
          </ItemContainer>
        );
      })}
      <button
        disabled={isLocked}
        onClick={() => controller.openAddTaskModal(rowData, rowIndex)}
        className="btn btn-link font-size-12 pl-4"
      >
        + Add Task
      </button>
    </>
  );
};

export const InnerDurationList = ({ tasks, getCellHeight, controller, isLocked }) => {
  const [editingRow, setEditingRow] = React.useState(null);

  React.useEffect(() => {
    if (editingRow !== null) {
      const onClick = e => {
        const { target } = e;
        if (target.tagName === 'INPUT') return;
        setEditingRow(null);
      };

      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [setEditingRow, editingRow]);

  return (
    <>
      {tasks.map((item, index) => {
        const task = item.set('durationDays', item.get('duration'));
        const height = getCellHeight(task, index);
        return (
          <ItemContainer key={`duration-${index}`} height={height}>
            {editingRow === index ? (
              <TaskDurationInput controller={controller} task={task} />
            ) : (
              <span
                onClick={isLocked ? null : () => setEditingRow(index)}
                className={cn('d-block pr-4', { editable: !isLocked, 'text-muted': isLocked })}
              >
                {task.get('durationDays')}d
              </span>
            )}
          </ItemContainer>
        );
      })}
    </>
  );
};

export const InnerPreconstructionList = ({ tasks, getCellHeight, controller, isLocked }) => {
  const onPreConstructionChange = rowData => e => {
    e.stopPropagation();
    const { checked } = e.target;
    controller.updateTaskField(rowData, 'isPreConstruction', checked);
  };

  return (
    <>
      {tasks.map((item, index) => {
        const task = item.set('durationDays', item.get('duration'));
        const isPreConstruction = !!task.get('isPreConstruction');
        const height = getCellHeight(task, index);

        return (
          <ItemContainer key={`preconstruction-${index}`} height={height}>
            <input
              type="checkbox"
              className="cursor-pointer"
              checked={isPreConstruction}
              disabled={isLocked}
              onChange={onPreConstructionChange(task)}
            />
          </ItemContainer>
        );
      })}
    </>
  );
};

export const InnerMultiFamilyList = ({ tasks, allTasks, getCellHeight, controller, modal, isLocked }) => {
  const { onMultiFamilyChange } = EventHandlers({ controller });

  return (
    <>
      {tasks.map((item, index) => {
        const task = item.set('durationDays', item.get('duration'));
        const isMultiFamily = !!task.get('isMultiFamily');
        const height = getCellHeight(task, index);

        return (
          <ItemContainer key={`multifamily-${index}`} height={height}>
            <input
              type="checkbox"
              className="cursor-pointer"
              checked={isMultiFamily}
              disabled={isLocked}
              onChange={onMultiFamilyChange({ rowData: task, modal, tasks: allTasks })}
            />
          </ItemContainer>
        );
      })}
    </>
  );
};

export const InnerPredecessorsList = ({ tasks, template, getCellHeight, onClickDependencies, isLocked }) => {
  return (
    <>
      {tasks.map((task, index) => {
        const height = getCellHeight(task, index);
        const predecessorsString = getPredecessorsString(task.get('predecessors'), template.get('tasks'));
        const showRemoveDependencies = hasCrossMFDependency(task, template.get('tasks'));

        return (
          <ItemContainer key={`predecessor-${index}`} height={height}>
            <div
              className={isLocked ? 'text-muted' : 'editable'}
              onClick={isLocked ? null : () => onClickDependencies(task)}
            >
              {predecessorsString}
              <FontAwesomeIcon
                icon={predecessorsString ? 'pen' : 'pen-to-square'}
                className={predecessorsString ? 'ml-1' : ''}
              />
            </div>
            {showRemoveDependencies || task?.get('missingReference') ? (
              <div className="text-danger" style={{ marginLeft: '-10px' }}>
                {showRemoveDependencies ? 'Remove Dep.' : 'Missing'}
              </div>
            ) : null}
          </ItemContainer>
        );
      })}
    </>
  );
};

export const InnerDropDownActions = ({ tasks, getCellHeight, onSelect, isLocked }) => (
  <>
    {tasks.map((task, index) => {
      const height = getCellHeight(task, index);
      return (
        <ItemContainer key={`actions-${index}`} height={height} pt="1.4rem">
          <TableRowDropDown remove disabled={isLocked} onSelect={() => onSelect(task)} />
        </ItemContainer>
      );
    })}
  </>
);

import React from 'react';
import { Link } from 'react-router-dom';
import { fromJS, List, Map } from 'immutable';
import { markAsSideEffect, markAsSync } from '@tradetrax/web-common';
import { buildersService } from 'services';
import { openRemoveTaskDialog } from '../../Job/JobDetails/JobDetailsActions';
import { AddStageModal } from './AddStageModal';
import { AddTaskModal } from './AddTaskModal';
import { TEMPLATE_VIEW, TEMPLATE_STAGES_EXPANDED_STATE } from './TemplateDetailsContext';
import { RemoveKeyTaskModal } from './RemoveKeyTaskModal';
import { RemoveStageModal } from './RemoveStageModal';
import navigate from 'app/navigate';

export const emptyState = fromJS({
  template: {
    tasks: List(),
    tasksTotalCount: 10,
    name: '',
  },
  hasPermission: true,
  templateView: 'stages',
});

function rowIndex(template) {
  return template.update('tasks', tasks => tasks.map((task, index) => task.set('rowIndex', index + 1)));
}

function setTasksRowIndex(tasks) {
  return tasks.map((task, index) => task.set('rowIndex', index + 1));
}

function mapTemplate(template) {
  const result = fromJS(template).update('tasks', tasks => tasks.sortBy(task => task.get('order')));

  return rowIndex(result);
}

export function readTemplate(templateId) {
  return buildersService
    .readTemplate({}, { params: { templateId } })
    .then(mapTemplate)
    .then(template => state => state.set('template', template))
    .catch(error => {
      let hasPermission = true;
      if (error.httpCode === 404) hasPermission = false;
      return state => state.set('hasPermission', hasPermission);
    });
}

export async function updateTemplate(templateId, field, value, title) {
  const payload = { [field]: value };

  return buildersService
    .updateTemplate(payload, { params: { templateId } })
    .then(() => state => state.setIn(['template', field], value))
    .catch(err => {
      this.alert.add({
        message: `There was a problem updating the ${title}. Please try again.`,
        variant: 'danger',
      });
      throw err;
    });
}

markAsSideEffect(deleteTemplateRow);
export async function deleteTemplateRow(taskOrStage) {
  if (!taskOrStage.get('isStage')) return this.controller.deleteTask(taskOrStage);

  if (taskOrStage.get('tasks').size === 0) return this.controller.deleteStage(taskOrStage, false);

  const { isAccept, option } = await this.modal.open(RemoveStageModal);
  if (isAccept) this.controller.deleteStage(taskOrStage, option === 'with-tasks');
}

export function deleteStage(stage, removeTasks) {
  const templateId = this.state.getIn(['template', '_id']);
  const stageId = stage.get('_id');

  return buildersService
    .removeStageFromTemplate({}, { params: { templateId, stageId }, query: { removeTasks } })
    .then(() => {
      this.addAlert(
        removeTasks
          ? 'Stage and its tasks successfully removed from this template.'
          : 'Stage successfully removed from this template.'
      );
      return state =>
        state.update('template', template => {
          const stageIndex = template.get('stages').findIndex(stage => stage.get('_id') === stageId);
          template = template.deleteIn(['stages', stageIndex]);

          if (removeTasks) {
            template = template.update('tasks', tasks => {
              const tasksAux = tasks.filter(task => task.get('stageId') !== stageId);
              return setTasksRowIndex(tasksAux);
            });
          } else {
            template = template.update('tasks', tasks =>
              tasks.map(task => {
                if (task.get('stageId') === stageId) return task.set('stageId', null);
                else return task;
              })
            );
          }

          const stages = template.get('stages');
          let size = stages.filter(stage => !(stage.get('endTaskId') || stage.get('startTaskId'))).size;
          if (stages.size && stages.size === size) size -= 1;
          return template.set('isComplete', !size);
        });
    })
    .catch(err => {
      this.addAlert(
        removeTasks
          ? 'There was a problem removing this stage and its tasks from this template. Please try again.'
          : 'There was a problem removing this stage from this template. Please try again.',
        'danger'
      );
      throw err;
    });
}

markAsSideEffect(deleteTask);
export async function deleteTask(task) {
  const hasPredecessors = !!task.get('predecessors').size;
  const templateId = this.state.getIn(['template', '_id']);
  const taskId = task.get('id');

  if (hasPredecessors) {
    const { isAccept } = await openRemoveTaskDialog(task, this.modal);
    this.controller.dispatch([state => state]);
    if (!isAccept) return;
  }

  if (task.get('isKeyStart') || task.get('isKeyFinish')) return this.modal.open(RemoveKeyTaskModal, { templateId });

  return buildersService
    .removeTaskFromTemplate({}, { params: { templateId, taskId } })
    .then(mapTemplate)
    .then(template => {
      this.controller.dispatch([state => state.set('template', template)]);
      this.alert.success({ message: 'Task successfully removed from this template.' });
      this.tableRef.current.recomputeRowHeights();
    })
    .catch(() => {
      this.controller.dispatch([state => state]);
      this.alert.error({ message: 'There was a problem removing this task from the template. Please try again.' });
    });
}

// Private function, export not needed
function updateStageDuration(stage, duration) {
  const templateId = this.state.getIn(['template', '_id']);
  const stageId = stage.get('_id');
  const stageIndex = this.state.getIn(['template', 'stages']).findIndex(s => s.get('_id') === stage.get('_id'));
  return buildersService
    .updateTemplateStage({ duration: parseInt(duration, 10) }, { params: { templateId, stageId } })
    .then(() => state => state.setIn(['template', 'stages', stageIndex, 'duration'], parseInt(duration, 10)))
    .catch(() => {
      this.alert.error({ message: 'There was a problem updating this Stage. Please try again.' });
      return state => state;
    });
}

export function updateTaskField(task, field, value) {
  if (task.get('isStage')) {
    return Promise.resolve(state => state);
  }

  const templateId = this.state.getIn(['template', '_id']);
  const taskId = task.get('id');
  const currentTask = this.state.getIn(['template', 'tasks']).find(task => task.get('id') === taskId);
  const taskIndex = this.state.getIn(['template', 'tasks']).indexOf(currentTask);

  return buildersService
    .updateTaskFromTemplate({ [field]: value }, { params: { templateId, taskId } })
    .then(response => {
      const updatedTask = response.tasks.find(task => task.id === taskId);
      updatedTask.rowIndex = task.get('rowIndex');
      const { hasValidMultiFamilyDependencies, isComplete } = response;
      return state =>
        state
          .setIn(['template', 'tasks', taskIndex], fromJS(updatedTask))
          .mergeIn(['template'], { hasValidMultiFamilyDependencies, isComplete });
    })
    .catch(() => {
      this.alert.error({ message: 'There was a problem updating this Task. Please try again.' });
      return state => state;
    });
}

export function updateTaskDuration({ task, duration }) {
  if (task.get('isStage')) {
    return updateStageDuration.call(this, task, duration);
  }
  const templateId = this.state.getIn(['template', '_id']);
  const taskId = task.get('id');
  const currentTask = this.state.getIn(['template', 'tasks']).find(task => task.get('id') === taskId);
  const taskIndex = this.state.getIn(['template', 'tasks']).indexOf(currentTask);
  return buildersService
    .updateTaskFromTemplate({ duration: parseInt(duration, 10) }, { params: { templateId, taskId } })
    .then(response => {
      const updatedTask = response.tasks.find(task => task.id === taskId);
      updatedTask.rowIndex = task.get('rowIndex');
      return state => state.updateIn(['template', 'tasks'], tasks => tasks.setIn([taskIndex], fromJS(updatedTask)));
    })
    .catch(err => {
      this.alert.error({ message: 'There was a problem updating this Task. Please try again.' });
      return state => state;
    });
}

markAsSideEffect(openAddTaskModal);
export function openAddTaskModal(stage = null, rowIndex) {
  const globalTasks = this.appState.get('gtl');
  const templateId = this.state.getIn(['template', '_id']);
  const onUse = this.state.getIn(['template', 'onUse']);
  const title = 'Add Task';
  const stages = stage ? List() : this.state.getIn(['template', 'stages']);

  const addTask = ({ duration, stageId, accountability, ...addTaskParams }) => {
    const index = rowIndex || rowIndex === 0 ? 0 : stages.findIndex(stage => stage.get('_id') === stageId);
    duration = parseInt(duration, 10);

    this.controller.addTask({ duration, stageId, templateId, rowIndex: index, ...addTaskParams }).then(() => {
      if (addTaskParams.startDateConfirmation === 'yes')
        this.controller
          .createTemplateAccountability(accountability, templateId)
          .then(() => this.controller.readTemplate(templateId));
    });
  };

  this.modal.open(AddTaskModal, { title, globalTasks, addTask, stage, stages, templateId, onUse });
}

markAsSync(updateTaskOrder);
export function updateTaskOrder(
  state,
  { task, endOrder, isBefore, stageId, isOnTopEdge, isOnBottomEdge, orderAboveStages }
) {
  const stages = state.getIn(['template', 'stages']);
  const tasks = state.getIn(['template', 'tasks']);
  const above = tasks.filter(task => task.get('stageId') === null && task.get('orderAboveStages'));

  if (isOnTopEdge) {
    stageId = null;
    isBefore = true;
    orderAboveStages = true;
    endOrder = tasks.first().get('order');
  } else if (isOnBottomEdge) {
    stageId = null;
    isBefore = false;
    orderAboveStages = false;
    endOrder = tasks.last().get('order');
  }

  // dropped on a stage?
  if (endOrder === -1) {
    let order = above.last()?.get('order') || 0;
    const indexes = stages
      .map(stg => stg.get('_id'))
      .reduce((prev, stageId) => {
        const stageTasks = tasks.filter(task => task.get('stageId') === stageId);
        const firstOrder = stageTasks.size ? stageTasks.first().get('order') : order;
        const lastOrder = stageTasks.size ? stageTasks.last().get('order') : order;
        const isEmpty = stageTasks.size === 0;
        order = lastOrder;
        return prev.set(stageId, Map({ firstOrder, lastOrder, isEmpty }));
      }, Map());

    if (indexes.getIn([stageId, 'isEmpty'])) isBefore = false;

    endOrder = indexes.getIn([stageId, isBefore ? 'firstOrder' : 'lastOrder']);
  }

  const newOrder = (() => {
    // is the same? last orphan above into first stage, only task in stage into next stage, etc...
    if (task.get('order') === endOrder) return endOrder;
    if (task.get('order') < endOrder) {
      // moving the task down the list?
      return isBefore ? endOrder - 1 : endOrder;
    }
    // moving the task up the list?
    return isBefore ? endOrder : endOrder + 1;
  })();
  const templateId = state.getIn(['template', '_id']);
  const taskId = task.get('id');
  const payload = { newOrder, stageId, orderAboveStages };

  buildersService
    .reorderTaskFromTemplate(payload, { params: { templateId, taskId } })
    .then(mapTemplate)
    .then(template => this.controller.dispatch([state => state.set('template', template)]))
    .then(() => {
      const isIncomplete = (task.get('isKeyStart') || task.get('isKeyFinish')) && task.get('stageId') !== stageId;
      if (isIncomplete) {
        this.addAlert(IncompleteMessage(templateId), 'warning');
      }
    })
    .catch(() => {
      this.addAlert('There was an error changing order, operation not completed!', 'danger');
      this.controller.dispatch([state => state.setIn(['template', 'tasks'], tasks)]);
    });

  return (
    state
      //.updateIn(['template', 'isComplete'], value => value && !isIncomplete)
      .updateIn(['template', 'tasks'], tasks => {
        const index = tasks.indexOf(task);
        return tasks
          .remove(index)
          .insert(
            newOrder,
            task
              .set('order', newOrder)
              .set('stageId', stageId)
              .set('orderAboveStages', orderAboveStages)
          )
          .map((task, index) => task.set('rowIndex', index + 1).set('order', index));
      })
  );
}

markAsSideEffect(addTask);
export function addTask({ task, duration, templateId, stageId, isPreConstruction, isMultiFamily, rowIndex }) {
  const payload = {
    gtlTaskId: task.get('_id'),
    name: task.get('name'),
    trade: task.get('trade'),
    duration,
    isPreConstruction,
    isMultiFamily,
  };

  if (stageId) {
    payload.stageId = stageId;
  }

  const children = task.get('children')?.toJS();
  if (children?.length) payload.children = children;

  return buildersService
    .addTaskToTemplate(payload, { params: { templateId } })
    .then(response => {
      const { tasks, isComplete } = response;
      this.controller.dispatch([
        state => {
          const tasksAux = fromJS(tasks).sortBy(task => task.get('order'));
          return state.update('template', template =>
            template.set('tasks', setTasksRowIndex(tasksAux)).set('isComplete', isComplete)
          );
        },
      ]);
      this.addAlert('Task successfully added to template.');
      if (!isNaN(rowIndex)) {
        this.tableRef.current.recomputeRowHeights(rowIndex);
        this.tableRef.current.forceUpdateGrid();
      }
    })
    .catch(err => {
      this.addAlert('There was a problem adding this task to the template. Please try again.', 'danger');
      throw err;
    });
}

markAsSideEffect(createTemplateAccountability);
export function createTemplateAccountability(accountability, templateId) {
  const { taskId, taskName, relatedTaskId, relatedTaskName } = accountability.toObject();
  const payload = {
    taskId,
    taskName,
    relatedTaskId,
    relatedTaskName,
    templateId,
  };
  return buildersService.createAccountability(payload).catch(error => {
    this.alert.error({ message: 'There was a problem saving the Start Date Confirmation Setting. Please try again.' });
  });
}

markAsSideEffect(updateDependencies);
export function updateDependencies(predecessors, taskOrStage) {
  if (taskOrStage.get('isStage')) {
    this.controller.updateStageDependencies(taskOrStage, predecessors);
  } else {
    this.controller.updateTaskDependencies(taskOrStage, predecessors);
  }
}

markAsSideEffect(updateTaskDependencies);
export function updateTaskDependencies(task, predecessors) {
  const templateId = this.state.getIn(['template', '_id']);
  const taskId = task.get('id');
  return new Promise((resolve, reject) => {
    buildersService
      .updateTaskFromTemplate({ predecessors }, { params: { taskId, templateId } })
      .then(response => {
        const { hasValidMultiFamilyDependencies, isComplete } = response;
        const updatedTask = this.state.getIn(['template', 'tasks']).find(task => task.get('id') === taskId);
        const taskIndex = this.state.getIn(['template', 'tasks']).indexOf(updatedTask);
        const newTask = response.tasks.find(task => task.id === taskId);
        this.alert.success({ message: 'Predecessors for this Task successfully updated.' });
        this.controller.dispatch([
          state =>
            state
              .setIn(['template', 'tasks', taskIndex], fromJS(newTask))
              .mergeIn(['template'], { hasValidMultiFamilyDependencies, isComplete })
              .update('template', rowIndex),
        ]);
        resolve(response);
      })
      .catch(error => {
        this.alert.error({ message: 'There was a problem updating predecessors. Please try again.' });
        this.controller.dispatch([state => state]);
        reject(error);
      });
  });
}

markAsSideEffect(updateStageDependencies);
export function updateStageDependencies(stage, predecessors) {
  const templateId = this.state.getIn(['template', '_id']);
  const stageId = stage.get('_id');
  return new Promise((resolve, reject) => {
    buildersService
      .updateTemplateStage({ predecessors }, { params: { templateId, stageId } })
      .then(response => {
        const { hasValidMultiFamilyDependencies, isComplete } = response;
        const stageIndex = this.state.getIn(['template', 'stages']).findIndex(stage => stage.get('_id') === stageId);
        const newStage = response.stages.find(stage => stage._id === stageId);
        this.alert.success({ message: 'Predecessors for this Stage successfully updated.' });
        this.controller.dispatch([
          state =>
            state
              .setIn(['template', 'stages', stageIndex], fromJS(newStage))
              .mergeIn(['template'], { hasValidMultiFamilyDependencies, isComplete })
              .update('template', rowIndex),
        ]);
        resolve(response);
      })
      .catch(error => {
        this.alert.error({ message: 'There was a problem updating predecessors. Please try again.' });
        this.controller.dispatch([state => state]);
        reject(error);
      });
  });
}

export function removeMissingReference(task, templateId) {
  const isStage = task.get('isStage');
  const taskId = task.get('id');
  if (isStage) {
    const stageId = task.get('_id');
    const stageIndex = this.state.getIn(['template', 'stages']).findIndex(stage => stage.get('_id') === stageId);
    return buildersService
      .updateTemplateStage({ missingReference: false }, { params: { templateId, stageId } })
      .then(() => state => state.setIn(['template', 'stages', stageIndex, 'missingReference'], false));
  } else {
    const taskIndex = this.state.getIn(['template', 'tasks']).indexOf(task);
    return buildersService
      .updateTaskFromTemplate({ missingReference: false }, { params: { taskId, templateId } })
      .then(() => state => state.setIn(['template', 'tasks', taskIndex, 'missingReference'], false))
      .catch(() => state => state);
  }
}

markAsSync(toggleTemplateView);
export function toggleTemplateView(state) {
  const view = state.get('templateView') === 'list' ? 'stages' : 'list';
  localStorage.setItem(TEMPLATE_VIEW, JSON.stringify(view));
  return state.set('templateView', view);
}

markAsSync(loadDefaultView);
export function loadDefaultView(state) {
  const defaultView = localStorage.getItem(TEMPLATE_VIEW);
  return state.set('templateView', JSON.parse(defaultView) || 'stages');
}

markAsSideEffect(openAddStageModal);
export function openAddStageModal() {
  const { addStage } = this.controller;
  const template = this.state.get('template');
  const title = 'Add Stage';

  this.modal.open(AddStageModal, { template, addStage, title });
}

const linkToSettings = navigate.from.TemplateDetails.to.TemplateSettings;

const IncompleteMessage = templateId => (
  <>
    <strong>Incomplete Stages</strong>
    <br />
    <span>
      Go to {<Link to={linkToSettings({ templateId }, false)}>Stages Configuration</Link>} and complete the End of the
      Stages.
    </span>
  </>
);

export function addStage(template, stage) {
  const { _id, name } = stage.toObject();
  const templateId = template.get('_id');

  return buildersService
    .addStageToTemplate({ _id, name }, { params: { templateId } })
    .then(({ stages }) => {
      const isIncomplete = stages.length > 1;
      if (isIncomplete) {
        this.addAlert(IncompleteMessage(templateId), 'warning');
      }
      this.addAlert('Stage successfully added to the template.');
      return state =>
        state.update('template', template => template.set('stages', fromJS(stages)).set('isComplete', !isIncomplete));
    })
    .catch(err => {
      this.addAlert('There was a problem adding this stage to the template. Please try again.', 'danger');
      throw err;
    });
}

export function selectSubTask(subTask, parentTask, { target }) {
  const taskId = parentTask.get('id');
  const childId = subTask.get('_id');
  const templateId = this.state.getIn(['template', '_id']);
  const addToJob = target.checked;
  const taskIndex = this.state.getIn(['template', 'tasks']).indexOf(parentTask);
  const subTaskIndex = this.state.getIn(['template', 'tasks', taskIndex, 'children']).indexOf(subTask);
  return buildersService
    .updateTemplateTaskChild({ addToJob }, { params: { templateId, taskId, childId } })
    .then(() => state => state.setIn(['template', 'tasks', taskIndex, 'children', subTaskIndex, 'addToJob'], addToJob))
    .catch(() => {
      this.addAlert('There was a problem selecting this sub-task. Please try again.', 'danger');
      return state => state;
    });
}

markAsSync(toggleRowDnD);
export function toggleRowDnD(state, index, stageId) {
  const path = ['expandedRows', 'stages', stageId];
  const isExpanded = !!state.getIn(path);

  this.controller.updateTable([index]);

  return isExpanded ? state.deleteIn(path) : state.setIn(path, true);
}

markAsSync(toggleRow);
export function toggleRow(state, view, row, index) {
  const templateId = this.state.getIn(['template', '_id']);
  const id = row.get('isStage') ? row.get('_id') : String(row.get('id'));
  const path = ['expandedRows', view, id];
  const isExpanded = !!state.getIn(path);
  const newState = isExpanded ? state.deleteIn(path) : state.setIn(path, true);
  const expandedRows = newState.get('expandedRows');

  localStorage.setItem(TEMPLATE_STAGES_EXPANDED_STATE, JSON.stringify({ templateId, expandedRows }));

  this.controller.updateTable([index]);

  return newState;
}

markAsSync(loadLastStagesExpandedState);
export function loadLastStagesExpandedState(state, templateId) {
  const stored = JSON.parse(localStorage.getItem(TEMPLATE_STAGES_EXPANDED_STATE) || '{}');

  if (templateId === stored.templateId && stored.expandedRows) {
    return state.set('expandedRows', fromJS(stored.expandedRows));
  } else {
    localStorage.setItem(TEMPLATE_STAGES_EXPANDED_STATE, JSON.stringify({ templateId }));
  }

  return state;
}

markAsSideEffect(updateTable);
export function updateTable(rowIndexes) {
  setTimeout(() => {
    rowIndexes.forEach(index => this.tableRef.current.recomputeRowHeights(index));
    this.tableRef.current.forceUpdateGrid();
  }, 1);
}

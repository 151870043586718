import { fromJS } from 'immutable';
import { markAsSideEffect } from '@tradetrax/web-common';
import { MultipleAssigneeModal } from '@tradetrax/web-common/lib/Community/MultipleAssigneeModal';
import { plural, orderDesc } from '@tradetrax/web-common/lib/utils';
import { buildersService } from 'services';
import JobNew from 'app/pages/Job/JobNew';
import { BuildingNew } from './Building.new.modal';

export function loadMoreRows({ startIndex, stopIndex, communityId }) {
  return buildersService
    .readJobs({}, { query: { communityId, start_index: startIndex, stop_index: stopIndex } })
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;

      return state =>
        state
          .set('totalCount', totalCount)
          .update('jobs', jobs => jobs.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()));
    });
}

export function readCommunity(communityId) {
  return buildersService
    .readCommunity({}, { params: { communityId } })
    .then(community => {
      community.areaManagers = orderDesc(community.areaManagers, 'name');
      return state => state.set('community', fromJS(community));
    })
    .catch(error => {
      let hasPermission = true;
      if (error.httpCode === 404) hasPermission = false;
      return state => state.set('hasPermission', hasPermission);
    });
}

markAsSideEffect(openNewJobModal);
export function openNewJobModal(communityId) {
  const { modal, controller, alert, state } = this; //appContext
  const jobs = state.get('jobs');
  const communities = state
    .get('communities')
    .toJS()
    .map(({ _id, name }) => ({ _id, name }));
  const community = communities.filter(c => c._id === communityId) || null;
  modal.open(JobNew, { controller, communities, alert, community, jobs });
}

markAsSideEffect(openNewBuildingModal);
export function openNewBuildingModal(communityId) {
  const { modal, controller, alert, state } = this; //appContext
  const jobs = state.get('jobs');
  const communities = state
    .get('communities')
    .toJS()
    .map(({ _id, name }) => ({ _id, name }));
  const community = communities.find(c => c._id === communityId) || null;
  modal.open(BuildingNew, { controller, communities, alert, community, jobs });
}

markAsSideEffect(createJob);
export function createJob({ name, templateId, communityId, lotNumber, squareFootage }) {
  const job = { name, templateId, communityId };

  if (lotNumber) job.lotNumber = lotNumber;
  if (squareFootage) job.squareFootage = squareFootage;

  return buildersService.createJob(job).then(({ _id }) => _id);
}

markAsSideEffect(createBuilding);
export function createBuilding({ name, templateId, communityId, jobIds }) {
  const building = { name, templateId, jobIds };

  // TODO: update buildingId on associated jobs.

  return buildersService.createBuilding(building, { params: { communityId } }).then(({ _id }) => _id);
}

export function readCommunityUsers(communityId) {
  return buildersService
    .readCommunityUsers({}, { params: { communityId } })
    .then(users => state => state.set('users', fromJS(users)));
}

export function readCommunities() {
  return buildersService.readCommunities().then(communities => state => state.set('communities', fromJS(communities)));
}

markAsSideEffect(updateCommunityHeader);
export function updateCommunityHeader({ action, community }) {
  if (action !== 'update') return;

  this.controller.dispatch([state => state.setIn(['community', 'areaManager'], fromJS(community.builderAreaManager))]);
}

markAsSideEffect(openMultipleAssigneeModal);
export async function openMultipleAssigneeModal(initView) {
  const community = this.state.get('community');
  const props = {
    initView,
    community,
  };
  const { isAccept, areaManagers } = await this.modal.open(MultipleAssigneeModal, props);
  if (!isAccept) return;

  const communityId = community.get('_id');
  const areaManagersIds = areaManagers.map(item => item.get('_id'));
  buildersService
    .updateCommunity({ areaManagersIds }, { params: { communityId } })
    .then(() => {
      const orderedAreaManagers = orderDesc(areaManagers.toJS(), 'name');
      this.controller.dispatch([state => state.setIn(['community', 'areaManagers'], fromJS(orderedAreaManagers))]);
      this.alert.add({
        message: `Area ${plural(areaManagers.size, 'Manager')} successfully set for this Community.`,
        variant: 'success',
      });
    })
    .catch(error =>
      this.alert.add({
        message: 'There was a problem setting Area Manager for this Community. Please try again.',
        variant: 'danger',
      })
    );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { PopoverMultiFamily } from '../Popover/MultiFamily.jobs.popover';
import { plural } from '../../lib/utils';

export function JobName({ job, task, linkToJob }) {
  const lotNumber = job.get('lotNumber');
  const isMultiFamily = !!task.get('multiFamily');

  if (isMultiFamily) {
    const jobs = task.getIn(['multiFamily', 'jobs']);
    return (
      <>
        <PopoverMultiFamily multiFamily={task.get('multiFamily')} className="task-details">
          <span className="secondary-link cursor-pointer">{`${jobs.size} ${plural(jobs.size, 'Job')}`}</span>
        </PopoverMultiFamily>
      </>
    );
  }

  return (
    <>
      <Link className="secondary-link" to={linkToJob}>
        <div className="text-truncate">{job.get('name')}</div>
      </Link>
      {lotNumber && <span className="d-block text-muted text-truncate">{`LOT #${lotNumber}`}</span>}
    </>
  );
}

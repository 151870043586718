import * as yup from 'yup';

export const nameSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9.!#$%&'*+\-/=?^_`{|}~\\\s]+$/, 'Character not allowed')
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
});

export function checkValidName(name, customRoles, setError) {
  const nameExisis = customRoles.filter(role => role.get('name').toLowerCase() === name.toLowerCase());
  if (nameExisis.size > 0 && setError) setError('name', { type: 'notMatch', message: 'Name already exists' });
  return !nameExisis.size;
}

export const byDescOrder = (a, b) => {
  if (a.get('name') > b.get('name')) return 1;
  if (a.get('name') < b.get('name')) return -1;
  return 0;
};

import React from 'react';
import { fromJS, List } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { formatISO, mongoToTrx, datepickerFormatIncremented, isDoubleUR } from '@tradetrax/web-common/lib/utils';
import { AffectedList } from '@tradetrax/tasks-util';
import { useAppContext } from 'app/App.context';
import * as actions from './TasksAffectedActions';
import { StagesModel } from '@tradetrax/job-util';
import moment from 'moment';

export const IMPACT_VIEW = 'IMPACT_VIEW';
export const AFFECTED_VIEW = 'AFFECTED_VIEW';
export const CHANGE_DATE_VIEW = 'CHANGE_DATE_VIEW';
export const UR_VIEW = 'UR_VIEW';

const EMPTY_JOB_STAGES = List([]);

const emptyState = fromJS({
  stageCycleTimeImpact: 0,
  jobStages: EMPTY_JOB_STAGES,
});

export const TasksAffectedContext = (task, tasksGraph, props) => {
  const appContext = useAppContext();
  const {
    impactedTasksAndDates,
    affectedTaskMap,
    isOverdue,
    isUpdateRequest,
    isStartDate,
    isEndDate,
    isInDashboard,
    isDuration,
    isPredecessorUpdate,
    date,
    diffDays,
  } = props;
  const { tasksAffectedUtils, ...rest } = React.useMemo(() => {
    return getTasksAffectedUtils(task, tasksGraph, {
      impactedTasksAndDates,
      affectedTaskMap,
      isOverdue,
      isUpdateRequest,
      isStartDate,
      isEndDate,
      isInDashboard,
      isDuration,
      isPredecessorUpdate,
      date,
      diffDays,
    });
  }, [
    task,
    tasksGraph,
    impactedTasksAndDates,
    affectedTaskMap,
    isOverdue,
    isUpdateRequest,
    isStartDate,
    isEndDate,
    isInDashboard,
    isDuration,
    isPredecessorUpdate,
    date,
    diffDays,
  ]);
  const { initView, jobDelay, isStartUR, showBackButton, proposedDate } = rest;
  const tasksAffectedRef = React.useRef(tasksAffectedUtils);
  const tasksAffected = fromJS(tasksAffectedUtils.getList());
  const initState = emptyState
    .set('tasksAffected', tasksAffected)
    .set('tasksAffectedSize', tasksAffected.size)
    .set('showBackButton', showBackButton)
    .set('jobDelay', jobDelay)
    .set('modalView', initView)
    .set('proposedDate', proposedDate)
    .set('task', task);

  const [state, controller] = useController(actions, initState, {
    ...appContext,
    getStageCycleTimeImpact,
    tasksAffectedRef,
    isStartUR,
    tasksGraph,
    isUpdateRequest,
  });

  const jobId = task.getIn(['job', 'id']);
  const jobStages = state.get('jobStages');
  React.useEffect(() => {
    controller.readJobStages(jobId);
  }, [controller, jobId]);

  React.useEffect(() => {
    if (jobStages !== EMPTY_JOB_STAGES) controller.readStageImpact(proposedDate);
  }, [controller, proposedDate, jobStages]);

  return { state, controller };
};

export const getProposedDate = task => {
  const { changeRequest } = task.toObject();
  const isStartUR = changeRequest.get('type') === 'new-start-date-request';
  const jobDelay = changeRequest.get('jobDelay');
  return {
    isStartUR,
    jobDelay,
    date: isStartUR ? changeRequest.get('proposedStartDate') : changeRequest.get('proposedFinishDate'),
  };
};

const getInitValues = (task, props) => {
  const { isUpdateRequest, isStartDate, isEndDate, isInDashboard } = props;
  const proposedDate = {};
  let showBackButton = true;
  if (isUpdateRequest) {
    const { isStartUR, date, jobDelay } = getProposedDate(task);
    if (isStartUR) proposedDate.newStartDate = formatISO(date);
    else proposedDate.newEndDate = formatISO(date);
    const initView = isInDashboard ? UR_VIEW : AFFECTED_VIEW;
    showBackButton = isInDashboard;
    return { initView, isStartUR, proposedDate, jobDelay, showBackButton };
  } else if (props.isDuration || props.isPredecessorUpdate) {
    proposedDate.newEndDate = formatISO(props.date);
    return { initView: IMPACT_VIEW, jobDelay: props.diffDays, showBackButton, proposedDate };
  } else if (props.isOverdue) {
    const isOverdueFinish = task.get('overdue') === 'finish';
    const taskDate = isOverdueFinish ? task.get('expectedFinishDate') : task.get('startDate');
    const date = datepickerFormatIncremented(mongoToTrx(taskDate), 1);
    if (isOverdueFinish) proposedDate.newEndDate = formatISO(date);
    else proposedDate.newStartDate = formatISO(date);
    return { initView: CHANGE_DATE_VIEW, proposedDate, showBackButton };
  }

  if (isStartDate) proposedDate.newStartDate = formatISO(props.date);
  else if (isEndDate) proposedDate.newEndDate = formatISO(props.date);

  return { initView: IMPACT_VIEW, proposedDate, jobDelay: props.diffDays, showBackButton };
};

const getTasksAffectedUtils = (task, tasksGraph, props) => {
  const { impactedTasksAndDates, affectedTaskMap, isOverdue } = props;
  const { proposedDate, ...rest } = getInitValues(task, props);
  const taskId = task.get('id');
  const tasksAffectedUtils =
    props.isDuration || props.isPredecessorUpdate
      ? AffectedList.createAffectedListObjectFromDateChanges(tasksGraph, impactedTasksAndDates, affectedTaskMap, taskId)
      : AffectedList.createAffectedListObject(tasksGraph, taskId, proposedDate);

  const jobDelay = isOverdue ? tasksAffectedUtils.calculateNewDates().cycleTimeDifference : rest.jobDelay;
  return {
    tasksAffectedUtils,
    proposedDate,
    jobDelay,
    ...rest,
  };
};

function getStageCycleTimeImpact(task, proposedDate, jobStages, tasksAffectedUtils) {
  const { job } = task.toJS();
  const taskGraphClone = this.tasksGraph.clone();

  if (this.isUpdateRequest && isDoubleUR(task)) {
    proposedDate.newStartDate =
      proposedDate.newStartDate || formatISO(task.getIn(['changeRequest', 'proposedStartDate']));
    proposedDate.newEndDate = proposedDate.newEndDate || formatISO(task.getIn(['changeRequest', 'proposedFinishDate']));
  } else if (proposedDate.newStartDate) {
    taskGraphClone.setStartDate(task.get('id'), proposedDate.newStartDate);
    const updatedTask = taskGraphClone.getTask(task.get('id'));
    const taskEndDate = moment.utc(updatedTask.endDate).format();
    proposedDate.newEndDate = formatISO(taskEndDate);
  } else if (proposedDate.newEndDate) {
    taskGraphClone.setEndDate(task.get('id'), proposedDate.newEndDate);
    const updatedTask = taskGraphClone.getTask(task.get('id'));
    const taskStartDate = moment.utc(updatedTask.startDate).format();
    proposedDate.newStartDate = formatISO(taskStartDate);
  }

  const affectedTasks = tasksAffectedUtils.getList();
  const { newJobDates } = tasksAffectedUtils.calculateNewDates();
  const stagesModel = new StagesModel({ job, tasks: [], sortedStages: jobStages.toJS() });
  const stageCycleTimeImpact = stagesModel.getStageImpact(task.toJS(), affectedTasks, proposedDate, newJobDates);
  return stageCycleTimeImpact;
}

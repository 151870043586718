import React from 'react';
import { Link } from 'react-router-dom';
import { Initials } from '../Tags/initials';
import { PopoverStickOnHover } from '../Popover';
import { CTR, CTR_COLOR } from '../ToDo/CTR';
import { UserNameLabel } from '../Tags';
import { plural, getAssigneeBadgeClassName, mongoToText, getUserDomain } from '../utils';
import { PopoverWrapper } from './CalendarWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultifamilyIconSVG } from '../Popover/MultiFamily.icons.popover';

const getAssigneeClassName = loggedUser => assigneeAccount => {
  if (!assigneeAccount) return '';
  const { isBuilder, isTrade } = getUserDomain(loggedUser);
  const isAssignedToBuilder = isTrade && assigneeAccount.get('companyType') === 'builder';
  const assigneeField = isBuilder || isAssignedToBuilder ? 'companyId' : 'installerId';
  return getAssigneeBadgeClassName(
    assigneeAccount.get(assigneeField),
    loggedUser.get('accountId'),
    loggedUser.get('_id'),
    assigneeAccount.get('companyId')
  );
};

export function getEventContent(getIsInactive, loggedUser, navigate, linkToTaskDetails) {
  return eventRender(getIsInactive, getAssigneeClassName(loggedUser), navigate, linkToTaskDetails, loggedUser);
}

const eventRender = (getIsInactive, getClassName, navigate, linkToTaskDetails, loggedUser) => ({ event }) => {
  const { task, isPlaceholder } = event.extendedProps;
  const className = getClassName(task.get('assigneeAccount'));
  const isInactive = getIsInactive(task);
  const { isBuilder, isTrade } = getUserDomain(loggedUser);
  const isAssignedToBuilder = isTrade && task.getIn(['assigneeAccount', 'companyType']) === 'builder';
  const assigneeField = isBuilder || isAssignedToBuilder ? 'companyName' : 'installerName';
  const userName = task.getIn(['assigneeAccount', assigneeField]) || null;
  const changeRquestField = isTrade ? 'changeRequestOnReviewByBuilder' : 'changeRequest';
  const isURActive = isTrade && task.getIn(['changeRequestOnReviewByBuilder', 'state']) !== 'rejected-by-builder';
  const showPendingUR = task.get('changeRequest') || (task.get('changeRequestOnReviewByBuilder') && isURActive);
  const isMultiFamily = task.get('isMultiFamily');

  if (isPlaceholder) return <div />;

  return (
    <PopoverStickOnHover
      className="d-flex align-items-center h-100"
      popoverId="calendar-event-popover"
      popover={popover(task, isInactive, className, navigate, linkToTaskDetails, userName, showPendingUR)}
      delay={640}
      placement="bottom-start"
    >
      <span className="d-inline-block">
        <Initials name={userName || '-'} size={28} className="ml-1" />
      </span>
      <span className="d-inline-block text-truncate font-weight-bold">
        {event.title}
        {isBuilder && isMultiFamily && <MultifamilyIconSVG className="ml-1" />}
      </span>
    </PopoverStickOnHover>
  );
};

const popover = (task, isInactive, className, navigate, linkToTaskDetails, userName, showPendingUR) => {
  const { communityName, expectedFinishDate, startDate, durationDays, overdue } = task.toJS();

  const ctrStatus = task.getIn(['ctr', 'status']);
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const taskStatus = task.get('status');
  const lotNumber = task.getIn(['job', 'lotNumber']);
  const precedingTaskRef = task.getIn(['ctr', 'precedingTasksNotOnJob']);
  const overdueMessage =
    overdue === 'finish'
      ? `Missed Finish: ${mongoToText(expectedFinishDate)}`
      : overdue === 'start'
      ? `Missed Start: ${mongoToText(startDate)}`
      : '';

  return (
    <PopoverWrapper color={CTR_COLOR[ctrStatus]} className="pb-2">
      {lotNumber && <span className="mb-2 text-gray-400">{`LOT #${lotNumber}`}</span>}
      <UserNameLabel className={`mr-auto mb-3 ${className}`} isInactive={isInactive} hasPermission={false}>
        {userName || 'Needs Assignment'}
      </UserNameLabel>
      <CTR task={task} direction="row" className="mb-2" />
      <JobName task={task} navigate={navigate} />
      {communityName && <span className="mb-2">{communityName}</span>}
      <span className="mb-2">{plural.day(durationDays)}</span>
      {overdueMessage && (
        <small className="text-danger mb-2">
          <FontAwesomeIcon icon="calendar-days" className="mr-1" />
          {overdueMessage}
        </small>
      )}
      {precedingTaskRef && (
        <span className="mb-2 text-gray-400">
          <FontAwesomeIcon icon="circle-exclamation" className="text-yellow-100 mr-1" />
          Preceding task(s) not in job
        </span>
      )}
      {showPendingUR && (
        <span className="mb-3 text-gray-400">
          <FontAwesomeIcon icon="clock" className="mr-1" /> Pending update approval
        </span>
      )}
      <Link
        to={linkToTaskDetails({ jobId: jobId, taskId: taskId, status: taskStatus }, false)}
        className="align-self-end"
      >
        View full details
      </Link>
    </PopoverWrapper>
  );
};

export const JobName = ({ task, navigate }) => {
  const job = task.get('job');
  const jobId = job?.get('id');
  const jobName = job.get('name');
  const totalMultifamilyJobs = task?.getIn(['multiFamily', 'jobs'])?.size;

  if (task.get('isMultiFamily') && totalMultifamilyJobs > 1) {
    return <span>{`${totalMultifamilyJobs} Jobs`}</span>;
  }
  return (
    <>
      <Link className="mb-2" to={navigate.to.JobDetails({ jobId: jobId }, false)}>
        {jobName}
      </Link>
    </>
  );
};

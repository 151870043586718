import React, { useContext } from 'react';
import * as yup from 'yup';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import * as actions from './CustomRoleDetails.actions';
import { useAppContext } from 'app/App.context';
import { CustomRolesContext } from '../CustomRoles.context';

export const NOTIFICATIONS = 'notifications';
export const FILTER_SETS = 'filter-sets';
export const PERMISSIONS = 'permisions';

export const MY_TASKS = 'my-tasks';
export const RELEVANT_TASKS = 'relevant-tasks';
export const LEAD_TIME = 'lead-time';

export const emptyCustomRole = fromJS({
  name: 'the role name',
  active: true,
  settings: {
    notifications: {
      relevantTasks: [],
      leadTimeTasks: [],
    },
  },
});

const emptyState = fromJS({
  customRole: emptyCustomRole,
  tab: NOTIFICATIONS,
  selectedSection: MY_TASKS,
  filterSets: [],
});

export const nameSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9.!#$%&'*+\-/=?^_`{|}~\\\s]+$/, 'Character not allowed')
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
});

export const CustomRoleDetailsContext = React.createContext(null);
export const useCustomRoleDetailsContext = () => useContext(CustomRoleDetailsContext);

export const Context = roleId => {
  const appContext = useAppContext();
  const customRoleContext = CustomRolesContext();
  const loggedUser = appContext.appState.get('user');
  const { customRoles } = customRoleContext.state.toObject();
  const customRoleController = customRoleContext.controller;
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    customRoles,
    customRoleController,
  });

  React.useEffect(() => {
    controller.readCustomRole(roleId);
    controller.readFilterSets(roleId);
  }, [controller, roleId]);

  return { state, controller, loggedUser };
};

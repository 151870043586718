import React from 'react';
import styled from 'styled-components';
import { fromJS } from 'immutable';
import { Link } from 'react-router-dom';
import { colors } from '../theme';
import { PopoverStickOnHover } from './index';
import { useAppContext } from '../globalAppContext';

export function PopoverMultiFamily({ multiFamily, children, className = 'd-inline w-75' }) {
  return (
    <PopoverStickOnHover
      popover={popover(multiFamily.get('jobs'))}
      width={420}
      height={200}
      delay={600}
      className={className}
      placement="bottom-start"
      trigger="click"
    >
      {children}
    </PopoverStickOnHover>
  );
}

const popover = jobs => {
  const { navigate } = useAppContext();
  return (
    <div className="d-flex py-3 ml-2 flex-column">
      {jobs.map(job => {
        return (
          <LinkRow
            key={job.get('_id')}
            className="px-4 text-truncate w-100"
            to={navigate.to.JobDetails({ jobId: job.get('_id') }, false)}
          >
            {job.get('name')}
          </LinkRow>
        );
      })}
    </div>
  );
};

const LinkRow = styled(Link)`
  color: ${colors.gray800};
  padding: 0.5rem 0rem;

  &:hover {
    text-decoration: none;
    color: ${colors.gray800};
    background-color: ${colors.blue100};
  }
`;

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommunityHeader } from '@tradetrax/web-common/lib/Community';
import { UsersTags } from '@tradetrax/web-common/lib/Tags';
import { ShowMoreUsersModal } from '@tradetrax/web-common/lib/Modal';
import { IconButtonWrap } from '@tradetrax/web-common/lib/Button';
import { TableRowDropDown } from '@tradetrax/web-common/lib/Table/RowDropdownAction';
import { useAppContext } from 'app/App.context';

export function CommunityDetailsHeader({ communityContext, linkToOuttake }) {
  const { appState, modal } = useAppContext();
  const { state, controller } = communityContext;
  const { community } = state.toObject();
  const communityId = community.get('_id');
  const users = appState.getIn(['account', 'users']);
  const schedulers = state
    .getIn(['users', 'builderUsersScheduler'])
    ?.map(scheduler => ({
      name: scheduler.get('name'),
      status: scheduler.get('status'),
      initials: scheduler.get('initials'),
    }))
    .sort();
  const supers = state
    .getIn(['users', 'builderUsersSuper'])
    ?.map(scheduler => ({
      name: scheduler.get('name'),
      status: scheduler.get('status'),
      initials: scheduler.get('initials'),
    }))
    .sort();

  const onSelect = action => {
    if (action === 'create-job') {
      controller.openNewJobModal(communityId);
    } else if (action === 'create-building') {
      controller.openNewBuildingModal(communityId);
    }
  };
  const handleShowMoreUsers = () =>
    modal.open(ShowMoreUsersModal, { supers, schedulers, title: `Community: ${community.get('name')}` });

  return (
    <div className="d-flex">
      <CommunityHeader
        className="ml-4 px-1"
        community={community}
        controller={controller}
        users={users}
        domain="builders"
        loggedUserId={appState.getIn(['user', '_id'])}
        linkToOuttake={linkToOuttake}
      >
        <>
          <UsersTags
            className="mb-3 w-12rem"
            maxTags={2}
            title="Super Assigned"
            users={supers?.toArray().slice(0, 3) || []}
            onShowMore={handleShowMoreUsers}
          />
          <UsersTags
            className="mb-3 w-12rem"
            maxTags={2}
            title="Scheduler Assigned"
            users={schedulers?.toArray().slice(0, 3) || []}
            onShowMore={handleShowMoreUsers}
          />
        </>
      </CommunityHeader>
      <div className=" d-flex flex-column ml-auto mr-2 pb-3 align-items-end">
        <ManageContainer className="mr-2 btn btn-link" onClick={linkToOuttake}>
          <FontAwesomeIcon icon="list-check" className="mr-2" />
          {`Manage Outtake`}
        </ManageContainer>
        <TableRowDropDown
          className="mt-auto mb-3 mr-3"
          toggle={TogglePlusButton}
          onSelect={onSelect}
          createJob
          createBuilding
        />
      </div>
    </div>
  );
}

const ManageContainer = styled.button`
  display: block;
  white-space: nowrap;
  padding-top: 0;
  margin-bottom: 15px;
`;

const TogglePlusButton = React.forwardRef(({ onClick }, ref) => (
  <IconButtonWrap
    primary
    className="btn btn-primary"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon="plus" />
  </IconButtonWrap>
));
